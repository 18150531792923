<template>
    <div class="num_process">
        <div class="overview">
            <div class="text">
                <h1>수기 결제</h1>
                <p>
                    수기결제는 결제 장비없이 고객에게 최소한의 카드정보를 전달받아 카드승인을 하는 서비스입니다.<br /><br />
                    별도의 카드비밀번호, CVC 등 민감한 정보 없이 결제가 가능하며 PC나 모바일 환경에서 비대면 거래 시 효율적으로 사용이 가능합니다.
                </p>
            </div>
            <div class="img_box">
                <img src="~@/assets/image/pay/handwriting/overview.png" alt="수기 결제" />
            </div>
        </div>
        <ul class="pay_strength">
            <li>
                <img src="~@/assets/image/pay/handwriting/icon_strong_01.svg" alt="상품특징" />
                <p>간편함</p>
                <p>별도의 장비 및 솔루션 연동 없이 전화통화로만으로도 사용이 가능합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/handwriting/icon_strong_02.svg" alt="상품특징" />
                <p>저렴함</p>
                <p>티온페이는 고객님의 니즈에 맞춰 최저 수수료를 제공해드리며 별도 단말기가 필요 없어 도입비용이 없습니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/handwriting/icon_strong_03.svg" alt="상품특징" />
                <p>효율성</p>
                <p>수기결제 내역을 관리할 수 있는 매출관리시스템을 제공합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/handwriting/icon_strong_04.svg" alt="상품특징" />
                <p>다양함</p>
                <p>PC 및 모바일 등 모든 환경에 적용 가능하며 모든 카드사 사용이 가능합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/handwriting/icon_strong_05.svg" alt="상품특징" />
                <p>안전함</p>
                <p>티온페이는 정식 라이선스를 보유한 솔루션만 제공하여 안정되고 빠른 시스템 및 정보보호에 최선을 다합니다.</p>
            </li>
        </ul>
        <div class="pay_bottom_content">
            <div class="use_case">
                <h1>이용사례</h1>
                <ul>
                    <li>
                        <div class="wrapper">
                            <h3><i>A</i>대리운전 업체</h3>
                            <p>이용고객님과 전화 통화만으로 카드 승인을 통해 고객만족도가 증가했어요.</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3><i>B</i>방문판매 업체</h3>
                            <p>단말기를 들고 다니는 번거로움 없이 핸드폰으로 결제를 할 수 있어서 영업사원들이 너무 좋아해요.</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3><i>C</i>배달대행 업체</h3>
                            <p>추운 날씨 때문에 장비가 자주 고장이 났는데 수기결제서비스를 이용하여 원가 절감도 했고 배달기사분들도 좋아하시네요.</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3><i>D</i>텔레마케팅 업체</h3>
                            <p>결제수가 많지 않아 결제장비를 구매하는 것이 부담스러웠는데 수기결제를 통해 고민을 바로 해결했어요.</p>
                        </div>
                    </li>
                </ul>
                <div></div>
            </div>
            <div class="service_process">
                <h1>수기결제 프로세스</h1>
                <ul>
                    <li>
                        <div class="wrapper">
                            <h3>1</h3>
                            <p>결제 WEB/APP<br />사이트 접속 및 로그인</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>2</h3>
                            <p>최소한의 카드 정보입력<br />(카드번호, 유효기간)</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>3</h3>
                            <p>승인 버튼 클릭</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>4</h3>
                            <p>결제 완료</p>
                        </div>
                    </li>
                </ul>
                <div class="text_box">
                    <p class="l_exclamation">결제 WEB/APP Tool은 고객사의 환경에 맞춰 변경됩니다.</p>
                    <p class="l_exclamation">가맹점 환경에 따라 승인 한도 등이 부여될 수 있습니다.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'HandWriting'
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_pay.css"></style>