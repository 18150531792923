<template>
    <div class="pay c_content_container">
        <section class="c_section c_inner">
            <h1 class="l_page_title">결제서비스</h1>

            <LayoutTabTop :navList="navList" />

            <router-view></router-view>

        </section>
    </div>
</template>

<script>
import LayoutTabTop from '@/components/LayoutTabTop'    // 상단 메뉴

export default {
    name : 'Pay',
    components : {
        LayoutTabTop, // 상단 메뉴
    },
    data(){
        return {
            navList : [
                { to : '/pay/shoppingmall', content : '쇼핑몰 결제' },
                { to : '/pay/handWriting', content : '수기 결제' },
                { to : '/pay/sms', content : 'SMS/URL 결제' },
                { to : '/pay/virtual', content : '가상계좌 결제 서비스' },
                { to : '/pay/calculate', content : '선정산 서비스' },
                { to : '/pay/offline', content : '오프라인 결제 서비스' },
            ]
        }
    }
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_pay.css"></style>