<template>
    <div class="num_process">
        <div class="overview">
            <div class="text">
                <h1>오프라인 결제 서비스</h1>
                <p>
                    티온페이는 온라인 결제 시스템 외 오프라인 결제 서비스도 제공합니다.<br /><br />
                    창업 시 필수적으로 설치되는 POS, 단말기, KIOSK 등 비교 견적서비스를 제공하며, 무료 카드가맹신청부터
                    매출통합시스템 제공을 통해 성공적인 사업운영을 응원합니다.
                </p>
            </div>
            <div class="img_box">
                <img src="@/assets/image/pay/offline/overview.png" alt="쇼핑몰 결제" />
            </div>
        </div>
        <ul class="pay_strength">
            <li>
                <img src="@/assets/image/pay/offline/icon_strong_01.svg" alt="상품특징" />
                <p>다양성</p>
                <p>고객님의 니즈에 맞는 디자인 및 기능 비교 제공을 통해 다양성을 추구합니다.</p>
            </li>
            <li>
                <img src="@/assets/image/pay/offline/icon_strong_02.svg" alt="상품특징" />
                <p>저렴함</p>
                <p>결제장비 최저가 제공 및 무료 카드가맹신청서비스 등을 통해 고객님의 원가절감을 도와드립니다.</p>
            </li>
            <li>
                <img src="@/assets/image/pay/offline/icon_strong_03.svg" alt="상품특징" />
                <p>철저함</p>
                <p>오픈부터 폐업까지 철저한 A/S 및 관리를 통해 불편함이 없도록 최선을 다합니다.</p>
            </li>
        </ul>
        <div class="pay_bottom_content">
            <div class="service_process">
                <h1>서비스 이용절차</h1>
                <ul>
                    <li>
                        <div class="wrapper">
                            <h3>1</h3>
                            <p>서비스 신청</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>2</h3>
                            <p>제품선택</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>3</h3>
                            <p>카드가맹점 신청</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>4</h3>
                            <p>장비 설치 및 교육</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>5</h3>
                            <p>서비스 이용</p>
                        </div>
                    </li>
                </ul>
                <div></div>
            </div>
            <div class="product_guide">
                <h1>제품 안내</h1>
                <ul>
                    <li>
                        <p>POS</p>
                        <img src="@/assets/image/pay/offline/product_01.jpg" alt="제품 안내" />
                    </li>
                    <li>
                        <p>유선단말기</p>
                        <img src="@/assets/image/pay/offline/product_04.jpg" alt="제품 안내" />
                    </li>
                    <li>
                        <p>KIOSK</p>
                        <img src="@/assets/image/pay/offline/product_03.jpg" alt="제품 안내" />
                    </li>
                    <li>
                        <p>무선단말기</p>
                        <img src="@/assets/image/pay/offline/product_02.jpg" alt="제품 안내" />
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name : 'Offline'
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_pay.css"></style>